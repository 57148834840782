:root {
   --primary-gold: rgb(255, 187, 0);
   --title-text: rgb(151, 147, 124);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html body {
  background-color: rgba(0,0,0,1.00);

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 768px) {
.container {
    max-width: 720px;
  }
}

@media (min-width: 576px) {
.container {
    max-width: 540px;
}
} 


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/****************** NAVBAR ******************/
.me-auto > a.active,  .me-auto > a:hover {
  border-top: 0.1rem solid var(--primary-gold);
;}

/* .row {
  margin-bottom: 0rem;
  padding-bottom: 0rem;
} */

/* .navbar {
  margin-bottom: 0rem;
} */


/****************** HEADER ******************/

.header-wrapper {
  position: relative;
  background: url(images/MAIN.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  display: flex;
  flex-direction: column;

}

.pricebox {
  width: 200px;
  display:block; 
  color: rgb(248, 216, 71);
  border: #7f807e;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  font-size: 18px;
  height: 30px;
  text-align: center;
  padding: auto;
  padding-top: auto;
  padding-bottom: auto;
  margin-top: 3px;
  background-color: black;
}

.announcementbox {
  width: 220px;
  display:block; 
  color: white;
  border: #7f807e;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  font-size: 13px;
  height: 90px;
  text-align: center;
  padding: auto;
  padding-top: auto;
  padding-bottom: auto;
  margin-top: 3px;
  background-color: black;
}

.announcementboxfont{
  font-size: 13px;
}

/****************** CONTENT ******************/
.lead {
  color: rgb(211, 210, 202) !important;
  font-size: small !important;
}

/****************** FOOTER ******************/
.box {
  padding: 30px 30px;
  background: black;
  position: relative;
  bottom: 0;
  width: 100%;
  color: gray;  
  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
}

.footer-Title {
  color: var(--title-text);
  font-size: medium;
}

.link-secondary > a.active,  .link-secondary > a:hover {
  border-top: 0.1rem solid var(--primary-gold) !important;
  color:rgb(255, 187, 0);
;}

.footer-link {
  color: rgb(167, 160, 142);
  text-decoration: none;
  font-size: small;
}

.footer-link:hover {
  color: rgb(238, 194, 83);
  font-size: small;
}

.footer-link-social:hover {
  border-color: rgb(233, 188, 41);
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  box-shadow: 4px 4px 50px rgb(240, 188, 76);
}

/****************** TEAM ******************/

.roundedBox {
  border-radius: 20px;
  background: #000000;
  width: 300px;
  height: 300px;
  text-align: center;
  padding-top: 10px;
  color:rgb(167, 160, 142);
  /* border: rgb(100, 91, 66); */
  /* border-style: solid; */
  border-width: 2px;
  margin: 10px;
  display: inline-block;
}

.roundedBox:hover {
  box-shadow: 4px 4px 10px rgb(121, 97, 47);
  border-color: rgb(194, 160, 59);
  border-style: solid;
  border-width: 2px;
}


/****************** TIMELINE ********************/

.timeline_area {
  position: relative;
  z-index: 1;
}
.single-timeline-area {
  position: relative;
  z-index: 1;
  padding-left: 180px;
}
@media only screen and (max-width: 575px) {
  .single-timeline-area {
      padding-left: 100px;
  }
}
.single-timeline-area .timeline-date {
  position: absolute;
  width: 180px;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-right: 60px;
}
@media only screen and (max-width: 575px) {
  .single-timeline-area .timeline-date {
      width: 100px;
  }
}
.single-timeline-area .timeline-date::after {
  position: absolute;
  width: 3px;
  height: 100%;
  content: "";
  background-color: #ebebeb;
  top: 0;
  right: 30px;
  z-index: 1;
}
.single-timeline-area .timeline-date::before {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #f1c40f;
  content: "";
  top: 50%;
  right: 26px;
  z-index: 5;
  margin-top: -5.5px;
}
.single-timeline-area .timeline-date p {
  margin-bottom: 0;
  color: #e6f510;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
}
.single-timeline-area .single-timeline-content {
  position: relative;
  z-index: 1;
  padding: 30px 30px 25px;
  border-radius: 6px;
  margin-bottom: 15px;
  margin-top: 15px;
  -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
  box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
  border: 1px solid #ebebeb;
}
@media only screen and (max-width: 575px) {
  .single-timeline-area .single-timeline-content {
      padding: 20px;
  }
}
.single-timeline-area .single-timeline-content .timeline-icon {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  width: 30px;
  height: 30px;
  background-color: #f1c40f;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  text-align: center;
  max-width: 30px;
  border-radius: 50%;
  margin-right: 15px;
}
.single-timeline-area .single-timeline-content .timeline-icon i {
  color: #ffffff;
  line-height: 30px;
}
.single-timeline-area .single-timeline-content .timeline-text h6 {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  color:#f1c40f;
}
.single-timeline-area .single-timeline-content .timeline-text p {
  font-size: 13px;
  margin-bottom: 0;
  color:rgb(211, 210, 202);
}
.single-timeline-area .single-timeline-content:hover .timeline-icon,
.single-timeline-area .single-timeline-content:focus .timeline-icon {
  background-color: #020710;
}
.single-timeline-area .single-timeline-content:hover .timeline-text h6,
.single-timeline-area .single-timeline-content:focus .timeline-text h6 {
  color: #d84907;
}


/* ANIMATION EFFECTS */
.fade-in-text {
  width: 230px;
  display: inline-block;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-size: 13px;
  color: #d09e07;
  border: #7f807e;
  background-color: #001029;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  padding-top: 10px; 
  padding-left: 10px;
  margin-top: 10px;
  animation: fadeIn linear 3s;
  -webkit-animation: fadeIn linear 2s;
  -moz-animation: fadeIn linear 2s;
  -o-animation: fadeIn linear 2s;
  -ms-animation: fadeIn linear 2s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


/****************** TRADE ******************/

.roundedBoxTrade {
  border-radius: 20px;
  background: #4e4e4e;
  width: 400px;
  height: 300px;
  text-align: center;
  padding-top: 20px;
  color:rgb(248, 246, 242);
  /* border: rgb(100, 91, 66); */
  /* border-style: solid; */
  border-width: 2px;
  margin-top: 50px;
  margin-right: 10px;
  margin-left: 10px;

  /* margin: 10px; */
  display: inline-block;
}

.roundedBoxTrade:hover {
  box-shadow: 4px 4px 10px rgb(121, 97, 47);
  border-color: rgb(194, 160, 59);
  border-style: solid;
  border-width: 1px;
}

.headerTrade {
  width: 300px;
  display: inline-block; 
  color: rgb(173, 157, 84);
}

/* Tutorials */

.link { color: rgb(241, 220, 101) ;
  text-decoration: none;
} 
.link:hover { color: rgb(151, 147, 124); } 

/* QA */
.qaDiv {
  width: 500px;
  color: #d09e07;
  background-color: #001029;
  text-align: Left;
  padding: 10px;
  margin-left: auto; 
  margin-right: auto;
}

.accordion{
  border-color: #d09e07 !important;
}

.accordion-button{
  color: #d09e07 !important;
  background-color: #001029  !important;
  border-color: #4e4e4e !important;
}
.accordion-body{
  color: #d09e07;
  background-color: #000000  !important;
}


.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #FEB7EE;
}

/* CHART */
.ChartCurrentPrice {
  width: 200px;
  display: inline-block; 
  color: rgb(248, 216, 71);
  border: #7f807e;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  font-size: 15px;
  height: 25px;
  text-align: center;
  padding: auto;
  padding-top: auto;
  padding-bottom: auto;
}
.RED {
  color: rgb(252, 19, 11);
  animation: fadeIn linear 3s;
  -webkit-animation: fadeIn linear 3s;
  -moz-animation: fadeIn linear 3s;
  -o-animation: fadeIn linear 3s;
  -ms-animation: fadeIn linear 3s;
}
.GREEN {
  color:rgb(16, 243, 27);
  animation: fadeIn linear 3s;
  -webkit-animation: fadeIn linear 3s;
  -moz-animation: fadeIn linear 3s;
  -o-animation: fadeIn linear 3s;
  -ms-animation: fadeIn linear 3s;
}

.font_size12 {
  font-size: 12;
}

